import React from 'react';
import './App.css';

import "./assets/css/templatemo-style.css";
import "./assets/css/owl.theme.default.min.css";
import "./assets/css/owl.carousel.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/animate.css";
import "./assets/css/font-awesome.min.css";

import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'

import {BrowserRouter, Route, Switch} from "react-router-dom";
import {rrfProps, store} from "./configs/firebase";
import HomePage from "./components/HomePage";
import ProductDetails from "./components/ProductDetails";
import MainLayout from "./layouts/MainLayout";
import 'react-notifications/lib/notifications.css';

import "./index.css"

import './i18n'


const  App = () => (
    <Provider store={store}>
     <ReactReduxFirebaseProvider {...rrfProps}>
         <BrowserRouter>
             <MainLayout>
             <Switch>
                 <Route path={"/"}  exact component={HomePage} />
                 <Route path={"/product"} exact component={ProductDetails} />
             </Switch>
             </MainLayout>
         </BrowserRouter>
    </ReactReduxFirebaseProvider>
    </Provider>
  );

export default App;
