import React from "react";
import {useTranslation} from "react-i18next";

const About = () => {

    const {t} = useTranslation();
    return (
        <section id="about" data-stellar-background-ratio="0.5">
            <div className="container">
                <div className="row">

                    <div className="col-md-6 col-sm-12">
                        <div className="about-info">
                            <div className="section-title wow fadeInUp" data-wow-delay="0.2s">
                                <h4>{t('amasia')}</h4>
                                <h2>{t('amasia_created')}</h2>
                            </div>

                            <div className="wow fadeInUp" data-wow-delay="0.4s">
                                <p>{t('desc1')}</p>
                                <p>{t('desc2')}</p>
                                <p>{t('desc3')}</p>
                                <p>{t('desc4')}</p>
                                <p>{t('desc5')}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-12">
                        <div className="wow about-image animated" data-wow-delay="0.6s" >
                            <img src="images/gorcaran.png" className="img-responsive" alt=""/>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default About;
