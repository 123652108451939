import React from "react";
import "./index.scss"
import {useTranslation} from "react-i18next";

const Carousel = () => {
    const [index, setIndex] = React.useState(0);
    const [delay, setDelay] = React.useState(5000);
    const [isRunning, setIsRunning] = React.useState(true);
    const {t} = useTranslation();

    useInterval(() => {

        if(index >= 2){
             setIndex(0);
             } else {
          setIndex(index + 1);
         }


    }, isRunning ? delay : null);

    const getSliderImage = () => {
        switch (index) {
            case 0: return "./images/certificates/List1.png";
            case 1: return "./images/certificates/List2.png";
            case 2: return "./images/certificates/List3.png";
        }
    };

    const getClassName = () => {
        return ""
        switch (index) {
            case 0: return "first";
            case 1: return "second";
            case 2: return "third";
        }
    };

    const getSliderText = () => {
         return <>
                   <h3>{t(`news_${index + 1}_desc`)}</h3>
                   <h1>{t(`news_${index + 1}`)}</h1>
                </>;
    };

    // React.useEffect(()=> {
    //     setTimeout(() => {
    //         if(index >= 2){
    //             setIndex(0);
    //         } else {
    //             setIndex(index + 1);
    //         }
    //     }, 5000)
    // }, [index]);

    return (
        <div className={"carousel_custom"}>
            <div className={"text"}>
                <p>{getSliderText()}</p>
            </div>
            <div className={"slider"}>
                <img  src={getSliderImage()} className={getClassName()} />
            </div>
        </div>
    )
};


function useInterval(callback, delay) {
    const savedCallback = React.useRef();

    // Remember the latest function.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    React.useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export default Carousel
