import React from "react";
import Home from "./components/Home";
import About from "./components/About";
import Gallery from "../Gallery";
import Team from "./components/Team";
import Menu2 from "./components/ProductsList";
import Testimotal from "./components/Testimotal";
import Contacts from "./components/Contacts";

const HomePage = ({hideMenu}) => (
    <>
    <Home />
    <About />
    {/*<Team />*/}
    <Menu2 />
    <Gallery hideMenu={hideMenu}/>
    <Testimotal />
    </>
);

export default HomePage;
