import React, {useState} from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Contacts from "../components/HomePage/components/Contacts";
import {NotificationContainer} from 'react-notifications';
import {withRouter} from "react-router-dom";



const MainLayout = ({children, location}) => {
     const [showMenu, setShowMenu] = useState(true);
    return (
        <>
            {showMenu && <Menu />}
            {React.cloneElement(children, { hideMenu: () => alert("hide") })}
            {location.pathname === "/" && <Contacts />}
            <Footer />
            <NotificationContainer/>
        </>
    )
}


export default withRouter(MainLayout)
