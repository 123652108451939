import React, {useState} from "react";
import {firebaseConnect} from "react-redux-firebase";
import {compose} from "redux";
import {connect}from "react-redux";
import { NotificationManager} from 'react-notifications';
import {useTranslation} from "react-i18next";



const Contacts = (props) => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const getLocation = () => {
        return new Promise((res, rej) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    res(position)
                },rej
                );
            } else {
                rej()
            }
        });
    };

    const {t} = useTranslation()


    const addSuggestion = (location) => {
        const {firebase} = props;
        firebase.push('suggestions', {
                name,
                email,
                createdAt: new Date().getTime(),
                message,
                location: location ? {
                    latitude: location.coords.latitude,
                    longitude: location.coords.longitude
                } : null
            }).then(res => {
                NotificationManager.success(t('your_suggestion_successfully_send'), '');
                setEmail("")
                setName("")
                setMessage("")
                setLoading(false)
            })

        }


    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault();
        getLocation().then(addSuggestion).catch(() => addSuggestion(null))


    }

    return (
        <section id="contact" data-stellar-background-ratio="0.5">
            <div className="container">
                <div className="row">
                    <div className="wow fadeInUp col-md-6 col-sm-12" data-wow-delay="0.4s">
                        <div id="google-map">
                            <div style={{width: "100%"}}>
                                <iframe width="100%" height="600"
                                        src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=%D4%B1%D5%B4%D5%A1%D5%BD%D5%AB%D5%A1+(My%20Business%20Name)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"
                                        frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"/>
                            </div>
                            <br/>
                            {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3647.3030413476204!2d100.5641230193719!3d13.757206847615207!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf51ce6427b7918fc!2sG+Tower!5e0!3m2!1sen!2sth!4v1510722015945" allowfullscreen></iframe>*/}
                        </div>
                    </div>

                    <div className="col-md-6 col-sm-12">

                        <div className="col-md-12 col-sm-12">
                            <div className="section-title wow fadeInUp" data-wow-delay="0.1s">

                                <h2>{t("amasia_with_llc")}</h2>
                            </div>
                        </div>

                        <form  className="wow fadeInUp" id="contact-form" role="form"
                              data-wow-delay="0.8s"
                              onSubmit={handleSubmit}
                        >

                            <h6 className="text-success">Your message has been sent successfully.</h6>

                            <h6 className="text-danger">E-mail must be valid and message must be longer than 1
                                character.</h6>

                            <div className="col-md-6 col-sm-6">
                                <input
                                    required={true}
                                    value={name}
                                    onChange={({target}) => setName(target.value)}
                                    type="text" className="form-control" id="cf-name" name="name" placeholder={t("name")}/>
                            </div>

                            <div className="col-md-6 col-sm-6">
                                <input
                                    required={true}
                                    value={email}
                                    onChange={({target}) => setEmail(target.value)}
                                    type="email" className="form-control" id="cf-email" name="email"
                                       placeholder={t("email")}/>
                            </div>

                            <div className="col-md-12 col-sm-12">

                            <textarea className="form-control" rows="6" id="cf-message" name="message"
                                      value={message}
                                      required={true}
                                      onChange={({target}) => setMessage(target.value)}
                                      placeholder={t('send_your_suggestions')}/>

                                <button type="submit" className={`form-control ${name && email && message && !loading ? "" : "disabled"}`} id="cf-submit" name="submit">{loading ? "...": t("send")}
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </section>
    )
};

export default compose(
    firebaseConnect(() => ['suggestions']),
    connect((state) => {
        const {
            firebase: {
                data
            }}= state;
        return {
            products: data.suggestions
        }
    })
)(Contacts)
