import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Carousel from "../../Carousel"

const Home = () => {

    return (
        <section id="home" className="slider" data-stellar-background-ratio="0.5">
            <div className="row">

                <div className="owl-carousel owl-theme owl-loaded owl-drag">
                    <div className="item item-first">
                        <div className="caption">
                            <div className="container">
                                <Carousel/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Home
