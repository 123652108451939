import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationAM from './lang/am';
import translationUS from './lang/us';
import translationRu from './lang/ru';

console.log(detector)

// the translations
const resources = {
    us: {
        translation: translationUS
    },
    am: {
        translation: translationAM
    },
    ru: {
        translation: translationRu
    }
};

i18n
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem("language") && localStorage.getItem("language").toLowerCase() || "am",
        fallbackLng: localStorage.getItem("language") && localStorage.getItem("language").toLowerCase() || "am", // use en if detected lng is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
