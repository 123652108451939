import React from "react";
import {useTranslation} from "react-i18next";

const Footer = () => {
    const {t} = useTranslation();
    return (
        <footer id="footer" data-stellar-background-ratio="0.5">
            <div className="container">
                <div className="row">

                    <div className="col-md-3 col-sm-8">
                        <div className="footer-info">
                            <div className="section-title">
                                <h2 className="wow fadeInUp" data-wow-delay="0.2s">{t('address')}</h2>
                            </div>
                            <address className="wow fadeInUp" data-wow-delay="0.4s">
                                <p>{t("address_1")}</p>
                            </address>
                        </div>
                    </div>

                    <div className="col-md-5 col-sm-8">
                        <div className="footer-info">
                            <div className="section-title">
                                <h2 className="wow fadeInUp" data-wow-delay="0.2s">{t('contact_with_us')}</h2>
                            </div>
                            <address className="wow fadeInUp" data-wow-delay="0.4s">
                                <p>{t('director')} <b>{t('director_name')} </b>(+374 94) 42 14 16</p>
                                <p>{t('sales_department')} <b>{t("sales_department_name")}</b>(+374 91) 22 82 47</p>
                                <p>{t('production_department')} <b> info@amasiacheese.am</b>
                                    <br/>
                                    (+374 94) 42 14 16 <br/>
                                    (+374 91) 47 39 92
                                </p>
                            </address>
                        </div>
                    </div>

                    <div className="col-md-2 col-sm-4">
                        <ul className="wow fadeInUp social-icon" data-wow-delay="0.4s">
                            <li><a href="https://www.facebook.com/Amasia-Head-Cheesefactory-181610732405211/" className="fa fa-facebook-square" attr="facebook icon"/></li>
                            <li><a href="#" className="fa fa-twitter"/></li>
                            <li><a href="#" className="fa fa-instagram"/></li>
                            <li><a href="#" className="fa fa-google"/></li>
                        </ul>

                        <div className="wow fadeInUp copyright-text" data-wow-delay="0.8s">
                            <p><br/>Copyright &copy; {new Date().getFullYear()} </p>{t("amasia_with_llc")}
                        </div>
                    </div>

                </div>
            </div>
        </footer>
    )
}

export default Footer
