import React from "react";
import  "./index.css"
import ImagePreview from "./preview";
import {withTranslation} from "react-i18next";




let brakePoints = [350, 1500, 1050];

const images = [
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.15752-9/93941906_802834996875163_1837165350261620736_n.jpg?_nc_cat=110&_nc_sid=b96e70&_nc_ohc=2Eb-0CofTFQAX_askws&_nc_ht=scontent.fevn5-1.fna&oh=855a2937a61ebf0ba05d67050e9b4b56&oe=5ECFCA83",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.15752-9/93044590_558776088098502_1073863358689574912_n.jpg?_nc_cat=101&_nc_sid=b96e70&_nc_ohc=0DDUojH1GRAAX-bDfXU&_nc_ht=scontent.fevn5-1.fna&oh=2fce552d823354570fc18c23554c7780&oe=5ECEDFC0",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/79377558_571594183406862_2819116670809800704_n.jpg?_nc_cat=104&_nc_ohc=j4dvBK7VRowAX_hJ6DE&_nc_ht=scontent.fevn5-1.fna&oh=3cecdb5fd84c2cedf657fe6f220d19a2&oe=5EBEAE1C",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/79378241_571594146740199_1338352104816771072_n.jpg?_nc_cat=109&_nc_ohc=1H-0-CyZPEIAX_1pwN9&_nc_ht=scontent.fevn5-1.fna&oh=a3f9b96147a8f6e97846436e4cd67342&oe=5EF3CB14",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/78633634_571594036740210_6669220522829545472_n.jpg?_nc_cat=102&_nc_ohc=BOYyTcIIgHgAX9soir-&_nc_ht=scontent.fevn5-1.fna&oh=6d7626264a259942907156321eba2988&oe=5F01F1D7",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/67141308_480300605869554_345812568897486848_n.jpg?_nc_cat=100&_nc_ohc=4YoH7kNxxvUAX8f6fwl&_nc_ht=scontent.fevn5-1.fna&oh=9751242f6bf2d05cabea7484e2662fa3&oe=5EFBD112",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/79206629_571594113406869_6617312377018777600_n.jpg?_nc_cat=109&_nc_ohc=gJ5fQoarYWAAX_YBrqe&_nc_ht=scontent.fevn5-1.fna&oh=59de027ca0a7ff59a6c13196cfc75114&oe=5EF9B8D0",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/67065882_480081412558140_2769948383421399040_n.jpg?_nc_cat=102&_nc_ohc=hMdZs-JqKBMAX9Re6xw&_nc_ht=scontent.fevn5-1.fna&oh=446bdf209373f8eba3a82175d5fdcc23&oe=5EBBA815",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/67089400_480080895891525_1252467134152310784_n.jpg?_nc_cat=103&_nc_ohc=wg-CdV5_24UAX_vz8mf&_nc_ht=scontent.fevn5-1.fna&oh=82d348d4d613f916c713176b9373968b&oe=5EB7EEE0",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/30516569_239480749951542_7313271703751098368_o.jpg?_nc_cat=104&_nc_ohc=TIzEVqTgHhcAX8OXgUj&_nc_ht=scontent.fevn5-1.fna&oh=f35ea7784298d8120bcb501272cbf499&oe=5EFAE67F",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/67162417_480079875891627_3509148358558089216_n.jpg?_nc_cat=109&_nc_ohc=z5IBIkYPEz4AX8xjLy2&_nc_ht=scontent.fevn5-1.fna&oh=ba12d7792ab4ea981617d2f6d4677cea&oe=5F0004EB",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/61638515_450247862208162_3587775465852502016_n.jpg?_nc_cat=106&_nc_ohc=AxXEiQsUBt4AX-PduSS&_nc_ht=scontent.fevn5-1.fna&oh=a22ef028f4c3fe2b959f7af51d3dbcc6&oe=5EEF5660",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/22449979_182292249003726_1179057833573195746_n.jpg?_nc_cat=109&_nc_ohc=TcZ84hRvsMsAX-x_1C4&_nc_ht=scontent.fevn5-1.fna&oh=1082274b25d00a2c8c988578a05b57fa&oe=5EF7F835",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/22448324_182291475670470_7868572985631635055_n.jpg?_nc_cat=102&_nc_ohc=BSRVdTHqhKcAX9pfjlT&_nc_ht=scontent.fevn5-1.fna&oh=1fdd2626ea97ffef2a8881c4acaab71b&oe=5EBBEBA0",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/22490085_181619225737695_5817364492064748620_n.jpg?_nc_cat=106&_nc_ohc=oLO2jX8Ba3gAX-GC2KC&_nc_ht=scontent.fevn5-1.fna&oh=6b620f1762d10eeb607edbe0dfba172e&oe=5EB8C403",
    "https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/22448672_181615615738056_3591226689851364227_n.jpg?_nc_cat=100&_nc_ohc=RU9Qul42iI0AX-TQ5qX&_nc_ht=scontent.fevn5-1.fna&oh=c3dfc7971954a6daca5a60778a697bc6&oe=5F001C38",
    'https://scontent.fevn5-1.fna.fbcdn.net/v/t1.0-9/30414624_239483389951278_2522880930884878336_o.jpg?_nc_cat=104&_nc_ohc=Wz5hMFwyXtUAX9RAeYG&_nc_ht=scontent.fevn5-1.fna&oh=71b9eb9d7f7cd7bfff6edaf29bda42c7&oe=5EF457F3'

]

class Gallery extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            isOpen: false,
            photoIndex: 0
        }
    }
    render(){
        const {t} = this.props;
        return (
            <section id="gallery" data-stellar-background-ratio="0.5">
            <div className="container" style={{textAlign: "center"}}>
                <h1>{t('menu.gallery')}</h1>
                <div className="masonry-container">
                    <Masonry brakePoints={brakePoints}>
                        {images.map((image, photoIndex) => {
                            return (
                                <Tile src={image} onClick={() => {
                                    this.setState({isOpen: true, photoIndex});
                                    // this.props && this.props.hideMenu()
                                }}  />
                            )
                        })}
                    </Masonry>
                </div>
                <ImagePreview images={images} {...this.state} self={this} />
            </div>
            </section>
        )
    }
}

const Tile = ({src, onClick}) => {
    return (
        <div className="tile">
            <img src={src} onClick={onClick} />
        </div>
    );
};

class Masonry extends React.Component{
    constructor(props){
        super(props);
        this.state = {columns: 1};
        this.onResize = this.onResize.bind(this);
    }
    componentDidMount(){
        this.onResize();
        window.addEventListener('resize', this.onResize)
    }

    getColumns(w){
        return this.props.brakePoints.reduceRight( (p, c, i) => {
            return c < w ? p : i;
        }, this.props.brakePoints.length) + 1;
    }

    onResize(){
        const columns = this.getColumns(this.refs.Masonry.offsetWidth);
        if(columns !== this.state.columns){
            this.setState({columns: columns});
        }

    }

    mapChildren(){
        let col = [];
        const numC = this.state.columns;
        for(let i = 0; i < numC; i++){
            col.push([]);
        }
        return this.props.children.reduce((p,c,i) => {
            p[i%numC].push(c);
            return p;
        }, col);
    }

    render(){
        return (
            <div className="masonry" ref="Masonry">
                {this.mapChildren().map((col, ci) => {
                    return (
                        <div className="column" key={ci}>
                            {col.map((child, i) => {
                                return <div key={i} >{child}</div>
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }
}

export default withTranslation()(Gallery);
